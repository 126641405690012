<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="helpCenter-container">
        <div class="help-title">
            <span>帮助中心</span>
            <el-button class="common-screen-btn" type="primary" @click="() => { addEditShow() }">发布内容</el-button>
        </div>
        <div class="help-screen"> 
            <span>内容类型:</span>
            <el-select class="common-screen-input" @change="commonFun" v-model="params.type" placeholder="请选择" clearable filterable>
                <el-option label="客服干货" value="0"></el-option>
                <el-option label="客服风采" value="1"></el-option>
                <el-option label="操作手册" value="2"></el-option>
                <el-option label="行业资讯" value="3"></el-option>
            </el-select>
        </div>
        <div class="help-main">
            <el-table class="common-table" border style="margin: 0" height="100%" :data="tableData">
                <el-table-column prop="title" label="内容标题" :formatter="tableColumn" ></el-table-column>
                <el-table-column prop="profile" label="内容摘要" :formatter="tableColumn" ></el-table-column>
                <el-table-column prop="type" label="内容类型" :formatter="tableColumn" ></el-table-column>
                <el-table-column prop="createBy" label="发布人" :formatter="tableColumn" ></el-table-column>
                <el-table-column prop="createTime" label="发布时间" :formatter="tableColumn" ></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="() => { addEditShow(scope.row) }">编辑</el-button>
                        <el-button type="text" @click="() => { deleteFun(scope.row.id) }">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-contaoner">
            <el-pagination
                @size-change="(e) => pageFun(e, 'pageSize')"
                @current-change="(e) => pageFun(e)"
                style="text-align: center"
                :page-sizes="pagination.pageSizes"
                :page-size="params.pageSize"
                :current-page="params.pageNum"
                layout="total, prev, pager, next,sizes, jumper"
                :total="pagination.total"
                >
            </el-pagination>
        </div>
        <el-dialog v-dialogDrag title="发布内容" :append-to-body="true" :visible.sync="addVisible" width="720px" >
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" >
                <el-form-item label="内容标题" prop="title">
                    <el-input class="common-form-input_70" placeholder="请输入内容标题" v-model="ruleForm.title" maxLength="50" ></el-input>
                </el-form-item>
                <el-form-item label="内容类型" prop="type">
                    <el-select class="common-form-input_70" v-model="ruleForm.type" placeholder="请选择类型" filterable>
                        <el-option label="客服干货" value="0"></el-option>
                        <el-option label="客服风采" value="1"></el-option>
                        <el-option label="操作手册" value="2"></el-option>
                        <el-option label="行业资讯" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="内容摘要" prop="profile">
                    <el-input class="common-form-input_70" type="textarea" placeholder="请填写内容" :autosize="{ minRows: 4, maxRows: 4}" v-model="ruleForm.profile" show-word-limit maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="上传封面">
                    <div class="common-uploadImg-container">
                        <div class="common-uploadImg-add">
                            <i class="el-icon-plus"></i>
                            <p>上传封面</p>
                            <input class="common-uploadImg-input" @input="chooseFile" type="file">
                        </div>
                        <img v-if="ruleForm.img" class="common-uploadImg-img" :src="ruleForm.img" alt="">
                    </div>
                </el-form-item>
                <el-form-item label="正文内容" prop="content">
                    <quill-editor class="editor" :options="editorOption" v-model="ruleForm.content"></quill-editor>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="addVisible = false">取 消</el-button>
                <el-button :loading="btnLoading" class="common-screen-btn" type="primary" @click="() => { Addhelp() }">立即发布</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { tableColumn, Config } from '../../utils/index.js'
import { helpList, Addhelp, EditDeletehelp, helpDetail } from '../../service/common.js'
import { upload } from '../../service/upload.js'
import { quillEditor } from "vue-quill-editor";
import Quill from "quill";
import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageResize", ImageResize);
const toolbarOptions = [
  ["bold", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  [], // 引用  代码块-----['blockquote', 'code-block']
  [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  [], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [], // 文本方向-----[{'direction': 'rtl'}]
  [{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{ font: [] }], // 字体种类-----[{ font: [] }]
  [{ align: [] }], // 对齐方式-----[{ align: [] }]
  ["clean"], // 清除文本格式-----['clean']
  ["image"] // 链接、图片、视频-----['link', 'image', 'video']
];
export default {
    components: { quillEditor },
    data() {
        return {
            tableColumn,
            loading: false,
            addVisible: false,
            deleteVisible: false,
            btnLoading: false,
            tableData: [],
            params: {
                pageNum: 1,
                pageSize: 10,
                type: ''
            },
            pagination: {
                total: 0,
                pageSizes: [5, 10, 20, 50, 100]
            },
            ruleForm: { // 提交得数据
                title: '',
                img: '',
                profile: '',
                type: '',
                content: ''
            },
            rules: {
                title: [{ required: true, message: "请填写文章标题", trigger: "change" }],
                type: [{ required: true, message: "请选择文章类型", trigger: "change" }],
                profile: [{ required: true, message: "请填写文章摘要", trigger: "change" }],
                content: [{ required: true, message: "请填写文章内容", trigger: "change" }],
            },
            editorOption: {
                //  富文本编辑器配置
                theme: "snow",
                placeholder: "请输入",
                modules: {
                // imageDrop: true,
                imageResize: {
                    //添加
                    displayStyles: {
                    //添加
                    backgroundColor: "black",
                    border: "none",
                    color: "white"
                    },
                    modules: ["Resize", "DisplaySize", "Toolbar"] //添加
                },
                //  富文本编辑器配置
                toolbar: {
                    container: toolbarOptions
                }
                }
            }
        }
    },
    mounted() {
        this.helpList()
    },
    methods: {
        deleteFun(id) { // 删除
            this.$confirm('确定删除当前内容吗？', '删除帮助中心内容', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                customClass: 'deleteMessage',
                type: 'warning'
            }).then(() => {
                this.EditDeletehelp(id)
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });          
            });
        },
        async EditDeletehelp (id) { // 删除
            let { code } = await EditDeletehelp({id, isDeleted: 1})
            if (code == 200) {
                this.$message.success("删除成功")
                this.helpList()
            }
        },
        async Addhelp () {
            this.$refs["ruleForm"].validate(async (valid) => {
                if (valid) { //   请求
                    let ruleForm = { ...this.ruleForm }
                    let code
                    this.btnLoading = true
                    if ( ruleForm.id ) {
                        code = (await EditDeletehelp(ruleForm)).code
                    } else {
                        code = (await Addhelp(ruleForm)).code
                    }
                    this.btnLoading = false
                    if (code == 200) {
                        this.$message.success('添加成功')
                        this.helpList()
                        this.addVisible = false
                    }
                    return false;
                }
            });
        },
        async chooseFile(e) {
            let file = e.target.files[0];
            let format = file.name.split(".")[file.name.split(".").length - 1];
            if (file.size / 1024 > 1024 * 50) {
                e.target.value = "";
                return this.$message.error('文件大小不能超过50M')
            }
            if (!Config.fileType.imgType.includes(format)) {
                e.target.value = "";
                return this.$message.error(`只能上传 ${Config.fileMessage.imgType} 格式的文件`)
            }
            let { data } = await upload({ file: file })
            e.target.value = "";
            this.ruleForm.img = data.filename;
        },
        async addEditShow (row) { // 新增
            if (row) {
                let { data } = await helpDetail({id: row.id})
                this.ruleForm = {
                    title: data.title,
                    img: data.img,
                    profile: data.profile,
                    type: '',
                    content: data.content,
                    id: data.id,
                    isDeleted: 0
                }
                if (row.type == '客服干货') { this.ruleForm.type = '0' }
                if (row.type == '客服风采') { this.ruleForm.type = '1' }
                if (row.type == '操作手册') { this.ruleForm.type = '2' }
                if (row.type == '行业资讯') { this.ruleForm.type = '3' }
            } else {
                this.ruleForm = {
                    title: '',
                    img: '',
                    profile: '',
                    type: '',
                    content: ''
                }
                if (this.$refs["ruleForm"]) {this.$refs["ruleForm"].resetFields()}
            }
            this.addVisible = true
        },
        commonFun () { // 筛选调用
            this.params.pageNum = 1
            this.helpList()
        },
        async helpList() { // 获取帮助中心列表数据
            let params = {...this.params}
            this.loading = true
            let { data, code } = await helpList(params)
            this.loading = false
            if (code == 200) {
                this.tableData = data.list
                this.pagination.total = data.total
            }
        },
        pageFun(e, type) { // 分页
            if (type == "pageSize") {
                this.params.pageSize = e;
            } else {
                this.params.pageNum = e;
            }
            this.helpList();
        },
    }
}
</script>
<style lang="less" scoped>
.helpCenter-container {
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    .page-contaoner {
        padding-bottom: 32px;
    }
    // .common-table {
    //     height: 100%;
    // }
    /deep/.el-table th {
        background: #f7f7f7;
    }
    .help-main {
        flex: 1;
        overflow: auto;
        padding: 32px;
        box-sizing: border-box;
    }
    .help-title {
        display: flex;
        height: 72px;
        box-sizing: border-box;
        justify-content: space-between;
        padding: 0 32px;
        align-items: center;
        font-size: 24px;
        border-bottom: 1px solid #E6E6E6;
        span {
            font-size: 24px;
        }
    }
    .help-screen {
        height: 96px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 32px;
        border-bottom: 16px solid #eee;
    }
}
</style>
<style lang="less">
.deleteMessage {
    width: 300px !important;
    height: auto !important;
}

.editor {
  line-height: normal !important;
  width: 99%;
  height: 400px;
  box-sizing: border-box;
  margin-bottom: 32px;
  padding-bottom: 48px;
//   display: block;
//   font-size: 16px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
